.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
  // height: 173px;
  max-height: 667px;
  background: linear-gradient(180deg, #ff75ff 0%, #ffa895 100%), -webkit-linear-gradient(180deg, #ff75ff 0%, #ffa895 100%);
  border-radius: 10px;
  border: 2px solid #a32b97;
  padding: 10px 8px;

  .ad_container {
    height: 60px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    background: #b8217836;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & span {
      color: #b82998;
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 5px;
    }

    & button {
      background: #ffb800;
      box-shadow: 0px -3px 2px 0px #cd7c02 inset, 0px 4px 4px 0px #ffe68b inset, 0px 1px 2px 0px #00000033;
      color: #fff;
      width: 60%;
      -webkit-text-stroke: 1px #e78a00;
      padding: 3px 16px;
      border: 1.5px solid #a97a00;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        margin-left: 5px;
        height: 15px;
      }
    }
  }
}

.close_container {
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  &__title {
    font-size: 16px;
    letter-spacing: 0.03em;
    text-wrap: wrap;
    fill: #ffffff;
    stroke: #bd3cd2;
    stroke-width: 1.5px;
    text-transform: capitalize;
  }
}

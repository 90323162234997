.car {
  position: absolute;
  width: 30px;
  height: 30px;
  &_first {
    bottom: 4px;
    left: 2px;
  }
  &_second {
    top: 4px;
    right: 2px;
    opacity: 50%;
  }
}

.deadMenu
  position: absolute
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  gap: 30px

  width: 100%
  height: 100%
  background: rgb(0 0 0 / 80%)
  animation: 0.5s show ease

  @keyframes show
    from
      opacity: 0
    to
      opacity: 1

  .buttons
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 10px

@media (max-width: 1100px)

@media (max-width: 600px)

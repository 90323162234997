$sizes: (
  small: (
    width: 40px,
    height: 48px,
  ),
  medium: (
    width: 60px,
    height: 60px,
  ),
);

@keyframes flicker {
  0% {
    border-color: #FFFFFF;
  }
  100%{
    border-color: rgba(255, 255, 255, 0.5);
  }
}

$types: (
  factory: (
    bg: #f184f1,
    border: 2px solid #d14ed8,
    shadow: (
      inset 0px 3px 3px #ffb3ff,
      inset 0px -3px 3px #d662ff,
    ),
  ),
  factory-inactive: (
    bg: #c163dc,
    border: 2px solid #d14ed8,
    shadow: (
      inset 0px 3px 3px rgba(255, 179, 255, 0.4),
      inset 0px -3px 3px #af47d3,
    ),
  ),
  factory-selected: (
    animation: flicker 1s infinite linear alternate,

    bg: #c163dc,
    border: 3px solid #FFF,
    shadow: (
      inset 0px 3px 3px rgba(255, 179, 255, 0.4),
      inset 0px -3px 3px #af47d3,
    ),
  ),
  merging: (
    bg: #ca87f5,
    border: 2px solid #b446f1,
    shadow: (
      inset 0px 3px 3px #dfaeff,
      inset 0px -3px 3px #ba68ee,
    ),
  ),
  merging-inactive: (
    bg: #a746e8,
    border: 2px solid #b446f1,
    shadow: (
      inset 0px 3px 3px rgba(223, 174, 255, 0.5),
      inset 0px -3px 3px #9a32e0,
    ),
  ),
  merging-selected: (
    bg: #a746e8,
    border: 3px solid #FFF,
    shadow: (
      inset 0px 3px 3px rgba(223, 174, 255, 0.5),
      inset 0px -3px 3px #9a32e0,
    ),
  ),
  parking: (
    bg: #3fcff9,
    border: 2px solid #0086d1,
    shadow: (
      inset 0px 3px 3px #9de9ff,
      inset 0px -3px 3px rgba(0, 166, 237, 0.9),
    ),
  ),
  parking-inactive: (
    bg: #3aa5de,
    border: 2px solid #0086d1,
    shadow: (
      inset 0px 3px 3px #9de9ff,
      inset 0px -3px 3px rgba(0, 166, 237, 0.9),
    ),
  ),
  parking-selected: (
    animation: flicker 1s infinite linear alternate,

    bg: #3aa5de,
    border: 3px solid #ffffff,
    shadow: (
      inset 0px 3px 3px #9de9ff,
      inset 0px -3px 3px rgba(0, 166, 237, 0.9),
    ),
  ),
  parking-charging: (
    bg: #edc734!important,
    border: 3px solid #ffffff,
    shadow: (
      inset 0px 3px 2px #ffea97,
      inset 0px -2px 3px #d6aa00,
    ),
  ),
);

@each $size, $map in $sizes {
  .place-#{$size} {
    width: map-get($map, width);
    height: map-get($map, height);
  }
}

@each $type, $map in $types {
  .place-#{$type} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: map-get($map, bg);
    border: map-get($map, border);
    box-shadow: map-get($map, shadow);
    border-radius: 10px;
    padding: 0 3px;
    animation: map-get($map, animation);
  }
}

.topButton {
  position: absolute;
  top: -7px;
  right: -7px;
}

@keyframes movingFlare {
  0% {
    left: -30px;
    margin-left: 0px;
  }
  10% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}

@keyframes scaling {
  0% {
    transform: scale(1);
  }
  5%{
    transform: scale(1.2);
    z-index: 2;
  }
  10% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.jumpFlicker {
  /* Важные стили для анимации */
  position: relative;
  overflow: hidden;

  animation: scaling 10s ease-in-out 0.05s infinite, flicker 1s infinite linear alternate;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0.5) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0.5) 100%
    );
    left: -100px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 10s ease-in-out 0.05s infinite;
  }
}

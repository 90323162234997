.main
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 50px

  height: 100vh
  width: 100vw

  background-image: url('../svg/backgroundLoader.svg')
  background-size: cover

.tipText
  padding: 16px 26px

  border-radius: 31px
  border: 3px solid #8FA7FF
  background: rgba(255, 255, 255, 0.00)
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25)

  color: #8FA7FF
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  font-size: 24px
  font-style: normal
  font-weight: 400
  line-height: 19px /* 79.167% */
  letter-spacing: 0.72px
  white-space: pre-wrap
  text-align: center

.textLoad
  color: #B7BBFF
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.15)
  font-size: 40px
  font-style: normal
  font-weight: 400
  line-height: 19px
  letter-spacing: 1.2px
  text-transform: capitalize
  z-index: 10

  animation: flicker 1s infinite

  @keyframes flicker
    0%, 100%
      opacity: 1
    50%
      opacity: 0.7


.background
  position: absolute

.img
  max-height: 60%

@media (max-width: 1100px)
  .tipText
    font-size: 20px
    padding: 14px 24px

@media (max-width: 600px)
  .tipText
    font-size: 16px
    padding: 12px 14px

  .img
    max-width: 80%

$types: (
  factory: (
    bg: #f17cfa,
    shadow: (
      inset 2px 3px 2px #f9a8ff,
      inset 0px 3px 2px #f9a8ff,
      inset 0px -3px 2px #d766e0,
    ),
    stroke-color: #d644e1,
    content-bg: #db4df1,
    content-shadow: (
      inset 0px 2px 4px #d33deb,
      inset 0px -3px 4px #d33deb,
    ),
  ),
  merging: (
    bg: #d490fa,
    shadow: (
      inset 0px 3px 2px #e6baff,
      inset 0px -3px 2px #b562e3,
    ),
    stroke-color: #965bda,
    content-bg: #c65fff,
    content-shadow: (
      inset 0px 2px 4px #b43ff4,
      inset 0px -3px 4px #b43ff4,
    ),
  ),
  parking: (
    bg: #3dd3fb,
    shadow: (
      inset 0px 3px 2px #98e9ff,
      inset 0px -3px 2px rgba(4, 146, 193, 0.5),
    ),
    stroke-color: #2daee7,
    content-bg: #00aee8,
    content-shadow: (
      inset 0px 2px 4px #0099cc,
      inset 0px -3px 4px #0099cc,
    ),
  ),
);

@each $type, $map in $types {
  .#{$type} {
    padding: 5px 7px 7px;
    background: map-get($map, bg);
    border: 2px solid #c330e3;
    box-shadow: map-get($map, shadow);
    border-radius: 20px;
    & > header {
      text-align: center;
      max-height: 25px;
      & svg > text {
        font-size: 22px;
        letter-spacing: 0.03em;
        fill: #ffffff;
        stroke: map-get($map, stroke-color);
        stroke-width: 4px;
        text-transform: capitalize;
      }
    }
    & > .content {
      display: flex;
      flex-direction: row;
      height: calc(100% - 29px);
      gap: 3px;
      margin-top: 4px;
      padding: 5px;
      background: map-get($map, content-bg);
      box-shadow: map-get($map, content-shadow);
      border-radius: 15px;
    }
  }
}

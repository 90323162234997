.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > div:first-child {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 9px;
    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      background: #4eff38;
      border: 1.5px solid #1ea8ed;
      box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.25), inset 0px -3px 2px #18d900;
      border-radius: 11px;
      padding: 0 8px;
      & > svg > text {
        font-size: 14px;
        letter-spacing: 0.03em;
        fill: #ffffff;
        stroke: #26d124;
        stroke-width: 3px;
        text-transform: capitalize;
      }
    }
  }
}

.wrapParking__icon {
  position: relative;
  width: 22px;
  height: 22px;
  flex: 0 0 auto;
  & > input {
    display: none;
    & + label > span {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &_wrap {
    background-image: url('../../../assets/images/parking/icon-wrap.png');
  }
  &_unwrap {
    background-image: url('../../../assets/images/parking/icon-unwrap.png');
  }
  &_cb:checked + label > .wrapParking__icon_unwrap {
    display: none;
  }
  &_cb:checked + label > .wrapParking__icon_wrap {
    display: block;
  }
  &_cb:not(:checked) + label > .wrapParking__icon_unwrap {
    display: block;
  }
  &_cb:not(:checked) + label > .wrapParking__icon_wrap {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  overflow-y: auto;
  @media (max-width: 748px) {
    max-height: 170px;
  }
  &_overflow {
    padding-right: 6px;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a5d8ff;
    -webkit-box-shadow: inset 2px 1px 1px rgba(255, 255, 255, 0.25), inset -1px -1px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #0a9bdd;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #248dd0;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;
    margin: 5px;
  }
  &_unwrap {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
    padding: 12px;
    @media (max-width: 748px) {
      // max-height: 234px;
      max-height: 100%;
      gap: 6px;
      padding: 6px;
    }
  }
}

.container {
  position: relative;
  z-index: 1;

  $margin: -7px;
  $padding: 15px;
  padding: $padding calc($padding - $margin);
  margin: 0 $margin;

  .header {
    &Text {
      font-size: 20px;
      letter-spacing: 0.03em;
      text-wrap: wrap;
      fill: #ffffff;
      stroke: #45A8F6;
      stroke-width: 4px;
    }
  }

  .text {
    z-index: 2;
    color: #009EE1;
    font-family: Arial, serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;

  }

  .oneMark {
    top: 10px;
    position: absolute;
    width: 108px;
    z-index: -1;
  }

  .moreMarks {
    position: absolute;
    width: 100%;
    height: 100%;

    .one {
      top: -50px;
      left: -10px;
      position: absolute;
      width: 70px;
      z-index: -1;
    }

    .two {
      top: -20px;
      right: 50px;
      position: absolute;
      width: 108px;
      z-index: -1;
    }

    .three {
      top: -120px;
      position: absolute;
      width: 90px;
      right: 40%;
      z-index: -1;
    }
  }
}

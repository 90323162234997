.icon {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &_active {
    background-image: url('../../../../../assets/images/parking/icon-refueling-idle.png');
  }
  &_buy {
    background-image: url('../../../../../assets/images/parking/icon-coin.png');
    width: 100%;
    height: 50%;
    &_noBuy {
      opacity: 50%;
    }
  }
  &_locked {
    background-image: url('../../../../../assets/images/parking/icon-lock.png');
    &_noBuy {
      opacity: 50%;
    }
  }
  &_charging {
    background-image: url('../../../../../assets/images/parking/icon-refueling-charging.png');
  }
}

.price {
  color: #ffffff;
  font-size: 12px;
  -webkit-text-stroke: 3px #00a3d2;
}

.petrolTubes {
  width: 255px;
  height: 18px;
  margin-left: 15px;
  background-image: url('../../../../../assets/images/parking/TNM.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -18px;

  & svg {
    position: absolute;
    left: -1.5px;
    top: -2px;
  }
}

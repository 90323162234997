.icon {
  background-image: url('../../../../assets/images/merging/icon-empty.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 70%;
  height: 70%;
  &_small {
    width: 18px;
    height: 18px;
    &_noBuy {
      width: 100%;
      height: 45%;
    }
  }
  &_big {
    width: 31px;
    height: 31px;
    &_noBuy {
      width: 38px;
      height: 38px;
    }
  }
  &_lock {
    background-image: url('../../../../assets/images/parking/icon-lock.png');
  }
  &_noBuy {
    opacity: 50%;
  }
}
.plus {
  position: absolute;
  top: -6px;
  right: -6px;
}


.car {
  width: 35px;
  opacity: 50%;
  margin-bottom: 2px;
}
.score {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background-image: url("../../../../assets/images/merging/icon-score.png");
  background-size: 22px;
  background-position-x: right;
  padding: 32px;
  background-repeat: no-repeat;
}

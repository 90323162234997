.group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  margin-bottom: 22px;
  position: relative;
}

.petrolTubes {
  width: 255px;
  height: 18px;
  margin-left: 15px;
  background-image: url('../../../../assets/images/parking/petrol-tubes.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  @for $i from 1 through 4 {
    &_active#{$i} {
      background-image: url('../../../../assets/images/parking/petrol-tube-' + $i + '.png');
    }
  }
}

.level {
  height: 15px;
  width: 15px;
  text-align: center;
  border-radius: 50%;
  background: #a73ec887;
  color: #ffffff;
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 10px;
  line-height: 15px;
}

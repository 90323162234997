.newRecord
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  color: white
  font-size: 40px
  font-weight: 400
  letter-spacing: 0
  text-align: left

  margin-bottom: 5%

.text
  font-family: Arial, serif
  font-size: 24px
  font-weight: 400
  line-height: 12px
  letter-spacing: 0
  text-align: left


@media (max-width: 1100px)

@media (max-width: 600px)

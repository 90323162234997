$types: (
  bronze: (
    header-bg: #ea8395,
    content-bg: #ffcee6,
    item-bg: #ffbedd,
    stroke: #d1699b,
  ),
  silver: (
    header-bg: #b981ff,
    content-bg: #ffccf7,
    item-bg: #ffbef5,
    stroke: #ac69ff,
  ),
  gold: (
    header-bg: #ff916f,
    content-bg: #ffdbcc,
    item-bg: #ffcdb8,
    stroke: #d97448,
  ),
  ruby: (
    header-bg: #d274ff,
    content-bg: #ffccf7,
    item-bg: #ffbef5,
    stroke: #d14ed8,
  ),
  brilliant: (
    header-bg: #5ba8ef,
    content-bg: #c5e3ff,
    item-bg: #b2d9fe,
    stroke: #4f94d4,
  ),
);

@mixin icon {
  position: absolute;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

.container {
  width: 48.8%;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  border-radius: 10px 10px 0 0;
  &__text {
    font-size: 15px;
    line-height: 12px;
    fill: #ffffff;
    stroke-width: 3.5px;
    @each $type, $map in $types {
      &_#{$type} {
        stroke: map-get($map, stroke);
      }
    }
  }
  @each $type, $map in $types {
    &_#{$type} {
      background: map-get($map, header-bg);
    }
  }
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
  border-radius: 0 0 10px 10px;
  @each $type, $map in $types {
    &_#{$type} {
      background-color: map-get($map, content-bg);
    }
  }
  &__reward {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 11px;
    &__item {
      position: relative;
      display: flex;
      justify-content: flex-end;
      box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      margin-left: 10px;
      padding: 3px;
      @each $type, $map in $types {
        &_#{$type} {
          background: map-get($map, item-bg);
        }
      }
      &_hard {
        font-size: 15px;
        line-height: 12px;
        fill: #ffffff;
        stroke-width: 2.5px;
      }
      &_level {
        font-size: 14px;
        line-height: 12px;
        fill: #ffffff;
        stroke-width: 2.5px;
      }
      @each $type, $map in $types {
        &__text_#{$type} {
          stroke: map-get($map, stroke);
        }
      }
    }
  }
}

.icon_crown {
  display: block;
  align-self: center;
  width: 90px;
  height: 50px;
  margin: 0 20px;
  background-image: url('../../../../assets/images/rewards/icon-crown.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon {
  &_hard {
    @include icon;
    top: -5px;
    left: -10px;
    width: 35px;
    height: 30px;
    background-image: url('../../../../assets/images/common/icon-cash.png');
  }
  &_cup {
    @include icon;
    top: -6px;
    left: -12px;
    width: 36px;
    height: 36px;
    background-image: url('../../../../assets/images/rewards/icon-cup.png');
  }
  &_up {
    @include icon;
    top: 10px;
    left: 8px;
    width: 13px;
    height: 13px;
    background-image: url('../../../../assets/images/rewards/icon-up.png');
  }
  &_down {
    @include icon;
    top: 10px;
    left: 8px;
    width: 13px;
    height: 13px;
    background-image: url('../../../../assets/images/rewards/icon-down.png');
  }
}

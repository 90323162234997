@mixin icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px 5px 7px;
  background: #00cfff;
  border: 1px solid #56c5e7;
  box-shadow: inset 0px 3px 2px #8ae9ff, inset 0px -3px 2px #04a8ce;
  border-radius: 10px;
  &__label {
    font-size: 14px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #1d9afc;
    stroke-width: 2px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  }
  &__icon_wall {
    @include icon;
    background-image: url('../../assets/images/shop/icon-wall.png');
  }
  &__icon_story {
    @include icon;
    background-image: url('../../assets/images/shop/icon-story.png');
  }
  &__icon_message {
    @include icon;
    background-image: url('../../assets/images/shop/icon-message.png');
  }
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #ffffff;
  box-shadow: inset 0px 0px 2px 1px rgba(125, 125, 125, 0.3);
  border-radius: 7px;
  padding: 1px 0;
  &_text {
    font-family: Arial, sans-serif;
    font-size: 11px;
    color: #c132d8;
  }
}

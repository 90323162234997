.container
  width: 100%
  height: 100%
  position: absolute
  background: #00000066

  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  z-index: 100

.text
  font-size: 35px
  font-weight: 400
  line-height: 36px
  letter-spacing: 0
  text-align: left
  color: white

.imgFinger
  transform: translateX(-50%) translateY(-50%)
  position: absolute
  z-index: 1

  top: 50vh
  left: 50vw

  animation: orbita 2.5s linear infinite

.imgStick
  width: 185px
  height: 185px
  top: 50vh

.button
  font-size: 20px
  font-weight: 400
  line-height: 19px
  letter-spacing: 0.03em
  text-align: left
  color: white

@keyframes orbita
  from
    transform: rotate(0deg) translateX(50px) rotate(0deg)
  to
    transform: rotate(360deg) translateX(50px) rotate(-360deg)

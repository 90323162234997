.container_noFull {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1px 0;
}

.iconPreview {
  width: 40px;
  height: 40px;
}

.icon {
  width: 47px;
  &_idle {
    width: 36px;
  }
  &_fueling {
    width: 30px;
  }
}

.indicator {
  position: relative;
  border-radius: 6px;
  margin-top: -7px;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
  &_idle {
    background: #2b99dc;
    width: 48px;
    height: 10px;
  }
  &_fueling {
    background: #d2a635;
    width: 100%;
    height: 13px;
  }
  &_fill {
    position: absolute;
    width: 0%;
    height: 100%;
    background: #ffffff;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  &_text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    font-size: 9px;
    color: white;
    text-shadow: 1px 0 black, -1px 0 black, 0 1px black, 0 -1px black, 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
  }
}

.icon_charging {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 9px;
  height: 15px;
  background-image: url('../../../../../assets/images/parking/icon-charge.png');
  background-repeat: no-repeat;
  background-size: contain;
}

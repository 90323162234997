.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 230px;
  max-width: 320px;
  min-height: 173px;
  max-height: 667px;
  background: linear-gradient(180deg, #FF75FF 0%, #FFA895 100%), -webkit-linear-gradient(180deg, #FF75FF 0%, #FFA895 100%);
  border-radius: 10px;
  border: 2px solid #A32B97;
  padding: 10px 8px;
}

.close_container {
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .title {
    font-size: 20px;
    height: 30px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #bd3cd2;
    stroke-width: 3px;
  }
}

.text {
  text-align: center;
  color: white;
  font-size: 18px;
  text-shadow: 1px 2px black;
}

.panel {
  position: relative;
  width: 100px;
  height: 21px;
  background: #4c39a2;
  box-shadow: inset 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &__btnAdd {
    & > img {
      border: 2px solid #ffffff;
      box-shadow: inset 0px 4px 2px rgba(255, 255, 255, 0.35), inset 0px -2px 4px #2c80dc;
      border-radius: 5px;
    }
  }
  &__icon {
    transform: scale(1.5);
  }
  &__coin {
    position: relative;
    margin: 0 5px;
    &-notice {
      position: absolute;
      left: -12px;
      bottom: -20px;
      background: #4c39a2;
      padding: 1px 6px;
      border-radius: 5px;
      display: none;
      opacity: 0;

      &-active {
        display: block;
      }

      &-minus {
        color: red !important;
      }

      &-plus {
        color: green !important;
      }

      &-animation {
        animation: slide-in-blurred-top 3s backwards;
      }
    }
    & span {
      font-size: 14px;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      color: #ffffff;
    }
  }
}

@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-50px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}


.container
  display: flex
  flex-direction: column
  gap: 25px
  align-items: end
  margin-top: 30px

  >:first-child
    >:first-child
      width: 170px
      >:nth-child(3)
        width: 55px
        height: 55px

@media (max-width: 600px)
  .container
    gap: 10px
    margin-top: 20px

    >:first-child
      >:first-child
        width: 90px
        >:nth-child(3)
          width: 35px
          height: 35px

.button
  display: flex
  text-align: center
  justify-content: center

  top: 34px
  left: 785px
  padding: 8px 16px
  border-radius: 16px
  border: 1.5px solid

  font-size: 20px
  font-weight: 400
  line-height: 19px
  letter-spacing: 0.03em
  color: #FFFFFF

  gap: 10px

  z-index: 60

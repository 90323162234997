$countTypes: ('min', 'average', 'max');

@mixin icon {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin count {
  font-size: 19px;
  letter-spacing: 0.03em;
  fill: #ffffff;
  stroke-width: 3px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.27);
}

@mixin button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 125%;
  padding: 2px 0;
  border-radius: 10px;
}

@mixin buttonIcon {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 2px;
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  height: 85px;
  padding: 7px 12px;
  background: #f1dcb8;
  border: 2px solid #0086d1;
  box-shadow: inset 0px 3px 3px #fff3df, inset 0px -3px 3px #c0aa85;
  border-radius: 10px;
}

.blocked {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 2;
  width: 102%;
  height: 102%;
  background-color: rgba(22, 20, 47, 0.27);
  border-radius: 10px;
}

.icon {
  &_soft {
    @each $countType in $countTypes {
      &_#{$countType} {
        background-image: url('../../../../assets/images/shop/icon-soft-' + $countType + '.png');
        @include icon();
      }
    }
  }
  &_hard {
    @each $countType in $countTypes {
      &_#{$countType} {
        background-image: url('../../../../assets/images/shop/icon-hard-' + $countType + '.png');
        @include icon();
      }
    }
  }
}

.count {
  &_container {
    position: absolute;
    width: 100%;
    bottom: 25px;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  &_soft {
    @include count();
    stroke: #cd6d0e;
  }
  &_hard {
    @include count();
    stroke: #29911d;
  }
}

.price {
  &__button {
    @include button();
    background: #1ef002;
    border: 1px solid #3bb600;
    box-shadow: inset 0px 3px 2px #a6ff9a, inset 0px -3px 2px #24ce0d;
  }
  &__value {
    font-size: 12px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #16ba00;
    stroke-width: 2px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    text-transform: lowercase;
  }
  &__vk_icon {
    @include buttonIcon();
    background-image: url('../../../../assets/images/shop/icon-vk.png');
  }
  &__tg_icon {
    @include buttonIcon();
    background-image: url('../../../../assets/images/shop/icon-tg-star.svg');
  }
}

.rewarded {
  &__button {
    @include button();
    background: #ff4381;
    border: 1px solid #db1152;
    box-shadow: inset 0px 3px 1px #fa78a3, inset 0px -3px 2px #de2b65;
  }
  &__value {
    font-size: 12px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #db1152;
    stroke-width: 2px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    text-transform: lowercase;
  }
  &__icon {
    @include buttonIcon();
    width: 14px;
    height: 14px;
    margin-left: 0;
    background-image: url('../../../../assets/images/shop/icon-rewarded.png');
  }
}

.paid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 125%;
  padding: 2px 0 3px;
  background: #ffc86b;
  border-radius: 10px;
  &__text {
    font-size: 12px;
    letter-spacing: 0.03em;
    fill: #f3f3f3;
    stroke: #ce8915;
    stroke-width: 2px;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
    text-transform: lowercase;
  }
}

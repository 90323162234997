.indicator {
  position: relative;
  border-radius: 6px;
  margin-top: -7px;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
  &_idle {
    background: #2b99dc;
    width: 38px;
    height: 7px;
  }
  &_fueling {
    background: #d2a635;
    width: 100%;
    height: 13px;
  }
  &_fill {
    position: absolute;
    width: 0%;
    height: 100%;
    background: #ffffff;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
  }
  &_text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: Arial, sans-serif;
    font-size: 11px;
    color: #005688;
  }
}

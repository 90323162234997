.main
  display: flex
  flex-direction: column

  .childrenContainer
    padding-left: 15px
    display: flex
    flex-direction: column
    gap: 15px

  .title
    font-weight: bold

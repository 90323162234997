$panel-max-width: 340px;
$container-panel-padding: 12px;
$container-panel-padding-mobile: 5px;
$container-panel-gap: 3px;
$container-panel-gap-mobile: 3px;

$screen-max-width-mobile: 2 * $panel-max-width + $container-panel-gap-mobile + 4 * $container-panel-padding;
$screen-max-width-tablet: 3 * $panel-max-width + 2 * $container-panel-gap + 2 * $container-panel-padding;

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  // background: rgba($color: #000000, $alpha: 0.2);
  background: #02060cb8;
  z-index: 100;
}

.comp {
  z-index: 120;
}

@keyframes pointerAn-LB {
  0%,
  100% {
    transform: translate(0, 0) rotate(55deg);
  }
  50% {
    transform: translate(-20%, 20%) rotate(55deg);
  }
}

@keyframes pointerAn-LT {
  0%,
  100% {
    transform: translate(0, 0) rotate(130deg);
  }
  50% {
    transform: translate(-20%, -20%) rotate(130deg);
  }
}

@keyframes pointerAn-RB {
  0%,
  100% {
    transform: translate(0, 0) rotate(320deg);
  }
  50% {
    transform: translate(20%, 20%) rotate(320deg);
  }
}

@keyframes pointerAn-RT {
  0%,
  100% {
    transform: translate(0, 0) rotate(230deg);
  }
  50% {
    transform: translate(20%, -20%) rotate(230deg);
  }
}

.pointer-block {
  position: fixed;
  top: -500;
  left: -500;
  z-index: 125;
  // transition: all 2s ease-in;
  width: auto;
  height: auto;
}

.pointer-car {
  position: fixed;
  top: -20px;
  transform: scale(1.5) rotate(39deg);
  z-index: 117;
}

.pointer {
  height: auto;
  width: 60px;
  position: relative;
  z-index: 126;
}

.pointer-left-bottom {
  transform: translate(0, 0) rotate(55deg);
}

.pointer-left-bottom-animate {
  animation: pointerAn-LB 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.pointer-left-top {
  transform: translate(0, 0) rotate(130deg);
}

.pointer-left-top-animate {
  animation: pointerAn-LT 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.pointer-right-bottom {
  transform: translate(0, 0) rotate(320deg);
}

.pointer-right-bottom-animate {
  animation: pointerAn-RB 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.pointer-right-top {
  transform: translate(0, 0) rotate(230deg);
}

.pointer-right-top-animate {
  animation: pointerAn-RT 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.comment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 348px;
  height: min-content;
  max-height: 200px;
  padding: 10px;
  border: 1px solid #808080;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  color: #4d4d4d;
  text-align: center;
  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    &__step {
      display: inline-block;
      font-size: 20px;
      margin: 0 6px;
    }
  }
}

.buttonGreat {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffb800;
  border: 1.5px solid #cc9300;
  box-shadow: inset 0px 4px 4px #ffe68b, inset 0px -3px 2px #cd7c02;
  border-radius: 16px;
  width: 80%;
  padding: 5px 13px;
  &__label {
    font-size: 16px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #e78a00;
    stroke-width: 1px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    text-transform: capitalize;
  }
}

.buttonSkip {
  display: block;
  margin-left: auto;
  text-decoration: underline;
  opacity: .4;
}

.pers-block {
  position: fixed;
  &-left {
    left: -48px;
    bottom: -250px;
    & img {
      transform: scale(-1, 1);
    }
    @media (max-width: $screen-max-width-mobile) {
      & img {
        width: 60%;
      }
      bottom: -180px;
    }
  }

  &-right {
    right: -48px;
    bottom: -250px;
    @media (max-width: $screen-max-width-mobile) {
      & img {
        width: 60%;
      }
      right: -165px;
      bottom: -202px;
    }
  }

  &-img {
  }

  &-text {
    position: absolute;
    top: 0;
    background-color: #d9d9d9;
    border: 2px solid #64047c;
    box-shadow: 2px 3px 3px 0px #00000040;
    border-radius: 19px;
    padding: 16px 22px;
    color: #ab33e5;
    left: 0;

    &-left {
      width: 300px;
      @media (max-width: $screen-max-width-mobile) {
        text-wrap: wrap;
        width: unset;
        max-width: 65vw;
      }
      &::before {
        width: 43px;
        height: 33px;
        bottom: -33px;
        left: 30px;
        clip-path: path('M40.5659 0.498853L8.42748 28.3317C5.18928 31.1361 0.15423 28.8359 0.154194 24.5521L0.153988 0.498853L40.5659 0.498853Z');
      }
      &::after {
        width: 41px;
        height: 30px;
        bottom: -29px;
        left: 31px;
        clip-path: path('M38.1868 0.244971L8.50613 25.9493C5.26793 28.7537 0.232889 26.4535 0.232852 22.1697L0.232665 0.244972L38.1868 0.244971Z');
      }
    }

    &-right {
      @media (max-width: $screen-max-width-mobile) {
        text-wrap: wrap;
        max-width: 65vw;
      }
      &::before {
        width: 43px;
        height: 33px;
        bottom: -33px;
        right: 30px;
        clip-path: path('M0.135997 0.887447L33.2887 29.5114C36.5347 32.314 41.5714 29.9977 41.5563 25.7092L41.4692 1.03304L0.135997 0.887447Z');
      }
      &::after {
        width: 41px;
        height: 30px;
        bottom: -29px;
        right: 31px;
        clip-path: path('M0.75945 0.485476L30.5576 26.2916C33.7958 29.096 38.8308 26.7957 38.8308 22.512L38.831 0.485475L0.75945 0.485476Z');
      }
    }
    &::before {
      content: '';
      background-color: #64047c;
      box-shadow: 2px 3px 3px 0px rgba(0, 0, 0, 0.2509803922);
      border-top: unset;
      position: absolute;
    }
    &::after {
      content: '';
      background-color: #d9d9d9;
      border-top: unset;
      position: absolute;
    }
  }
}

.icon {
  width: 34px;
  height: 27px;
  background-image: url("../../../../assets/images/factory/icon-empty.png");
  background-size: contain;
  background-repeat: no-repeat;

  &_inactive{
    opacity: 50%;
  }
}

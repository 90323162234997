.container {
  position: relative;
  width: 340px;
  background: #3dd3fb;
  border: 1px solid #c330e3;
  box-shadow: inset 0px 3px 2px #98e9ff, inset 0px -3px 2px rgba(4, 146, 193, 0.5);
  border-radius: 20px;
  padding: 28px 13px 13px 13px;
  margin-top: 40px;
}

.header {
  position: absolute;
  top: -40px;
  left: 33px;
  width: 270px;
  height: 40px;
  background: #3dd3fb;
  border: 1px solid #c330e3;
  border-bottom: none;
  box-shadow: inset 0px 3px 2px #98e9ff;
  border-radius: 20px 20px 0 0;
  &_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  &__title {
    position: relative;
    top: 14px;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 90%;
    padding: 5px 0;
    background: #72d8ff;
    border: 2px solid #2ca4ea;
    box-shadow: inset 0px 2px 2px #d1f2ff, inset 0px -2px 2px #44b7e3;
    border-radius: 16px;
    &__text {
      font-size: 32px;
      letter-spacing: 0.03em;
      fill: #ffffff;
      stroke: #45a8f6;
      stroke-width: 4px;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
      text-transform: capitalize;
    }
  }
  &__extBg {
    width: 100%;
    height: 20px;
    background: #3dd3fb;
  }
}

.close_container {
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background: #3297d1;
  box-shadow: inset 0px 0px 2px 2px #2b88bd, inset 0px 0px 1px 2px rgba(187, 40, 255, 0.99);
  border-radius: 20px;
  padding: 11px 5px 11px 13px;
  &_container {
    max-height: 516px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    border-radius: 20px;
    padding: 2px 7px 2px 0;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #a5d8ff;
      -webkit-box-shadow: inset 2px 1px 1px rgba(255, 255, 255, 0.25), inset -1px -1px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid #2576a5;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: #2386c4;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      background-clip: padding-box;
      margin: 3px;
    }
  }
}

.container {
  position: relative;
  width: 340px;
  background: #3dd3fb;
  border: 1px solid #c330e3;
  box-shadow: inset 0 3px 2px #98e9ff, inset 0px -3px 2px rgba(4, 146, 193, 0.5);
  border-radius: 20px;
  padding: 28px 13px 13px 13px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    font-size: 24px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #45A8F6;
    stroke-width: 5px;
    text-transform: capitalize;
  }
}

.close_container {
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  border-radius: 20px;
  background: #3aa5de;
  height: 70%;

  &_container {
    max-height: 516px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 15px 7px 15px 0;
    overflow-y: auto;
    overflow-x: clip;
    box-shadow: 0px -4px 4px 0px #8494DAC9 inset, 0px 4px 4px 0px #8494DA94 inset;
    gap: 8px;

    &::-webkit-scrollbar-thumb {
      background-color: #a5d8ff;
      -webkit-box-shadow: inset 2px 1px 1px rgba(255, 255, 255, 0.25), inset -1px -1px 4px rgba(0, 0, 0, 0.25);
      border: 1px solid #2576a5;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: #2386c4;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      background-clip: padding-box;
      margin: 3px;
    }
  }
}

.text
  color: #FFF
  font-size: 23px
  font-style: normal
  font-weight: 400
  line-height: 12px
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35)

  position: absolute
  transform: translateY(-9vh)

.hide
  animation: bounce-in 0.5s

@keyframes bounce-in
  0%
    transform: scale(0) translateY(-9vh)

  50%
    transform: scale(1.25) translateY(-9vh)

  100%
    transform: scale(1) translateY(-9vh)

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 304px;
  max-height: 667px;
  min-height: 219px;
  background: linear-gradient(180deg, #FF75FF 0%, #FFA895 100%), -webkit-linear-gradient(180deg, #FF75FF 0%, #FFA895 100%);
  border-radius: 10px;
  border: 2px solid #A32B97;
  padding: 10px 8px;

  img {
    max-height: 101px;
  }
}

.buttons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;


  gap: 12px;
  border-radius: 8px;
  padding: 8px 16px;
  background: #B8217836;

  .text{
    font-size: 16px;
    letter-spacing: 0.03em;
    text-wrap: wrap;
    fill: #ffffff;
    stroke-width: 4px;
    text-transform: capitalize;
  }

  $button-width: 120px;
  $button-height: 35px;
  $button-border-radius: 16px;

  .cancel {
    width: $button-width;
    height: $button-height;
    border-radius: $button-border-radius;

    background: #FFB800;
    border: 1.5px solid #A97A00;
    box-shadow: 0px -3px 2px 0px #CD7C02 inset, 0px 4px 4px 0px #FFE68B inset,  0px 1px 2px 0px #00000033;

    .text {
      stroke: #E78A00;
    }
  }

  .delete {
    width: $button-width;
    height: $button-height;
    border-radius: $button-border-radius;

    background: #FE3636;
    border: 1.5px solid #DB1152;
    box-shadow: 0px -3px 2px 0px #D00D0D inset,  0px 3px 1px 0px #FF5656 inset;

    .text {
      stroke: #BD0000;
    }
  }
}

.close_container {
  height: 30px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  &__title {
    font-size: 22px;
    letter-spacing: 0.03em;
    text-wrap: wrap;
    fill: #ffffff;
    stroke: #bd3cd2;
    stroke-width: 1.5px;
    text-transform: capitalize;
  }
}

.btnScore {
  width: 32px;
  height: 32px;
  &_icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/images/factory/icon-timer.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

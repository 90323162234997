.countCoins
  display: flex
  flex-direction: column
  justify-content: center
  align-items: start

  .point
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

    .icon
      width: 25px
      height: 25px
      margin-right: 12px

    .text
      font-size: 20px

    .coin
      width: 30px
      height: 30px
      margin-left: 3px

.total
  z-index: 100
  display: flex
  flex-direction: column
  position: relative

  .text
    font-size: 24px

  .num
    z-index: 100

    margin-top: 20px

    color: white
    font-size: 48px
    line-height: 32px

    display: flex
    justify-content: center
    align-items: baseline
    flex-direction: row

    gap: 10px

.total::after
  z-index: 10
  content: ""
  position: absolute
  bottom: 16px
  left: 0
  right: 0
  height: 13px
  border-radius: 50%
  box-shadow: 0 18px 10px rgba(0, 0, 0, 0.2)
  pointer-events: none


.text
  color: #FFF
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35)
  font-size: 22px
  font-style: normal
  font-weight: 400
  line-height: 19px
  letter-spacing: 0.66px
  text-transform: capitalize
  text-align: center

@media (max-width: 1100px)

@media (max-width: 600px)

$vertical-main-padding: 20px;

$vertical-main-padding-mobile: 7px;
$horizontal-main-padding-mobile: 7px;

$statusBar-height: 330px;

$panel-max-width: 340px;
$container-panel-padding: 12px;
$container-panel-padding-mobile: 5px;
$container-panel-gap: 3px;
$container-panel-gap-mobile: 3px;

$screen-width: 3 * $panel-max-width + 2 * $container-panel-gap + 2 * $container-panel-padding;
$screen-width-tablet: 2 * $panel-max-width + $container-panel-gap + 2 * $container-panel-padding;
$screen-width-mobile: $panel-max-width + $container-panel-gap-mobile + 4 * $container-panel-padding-mobile;

$screen-max-width-tablet: 3 * $panel-max-width + 2 * $container-panel-gap + 2 * $container-panel-padding;
$screen-max-width-mobile: 2 * $panel-max-width + $container-panel-gap-mobile + 4 * $container-panel-padding;

.garage {
  display: grid;
  grid-template-rows: min-content 1fr;
  width: 100%;
}

.container {
  position: relative;
  display: flex;
  justify-content: center;
  width: $screen-width;
  height: 100%;
  padding: $vertical-main-padding 0;
  @media (max-width: $screen-max-width-tablet) {
    width: $screen-width-tablet;
  }
  @media (max-width: $screen-max-width-mobile) {
    width: $screen-width-mobile - 23px;
  }

  &_alignToStart {
    align-items: flex-start;
  }

  &_alignToEnd {
    align-items: flex-end;
  }
}

.statusBar {
  display: flex;
  justify-content: center;
  height: $statusBar-height;
  background-image: url('../../assets/images/backgrounds/backgroundLoader.svg');
  background-attachment: fixed;
  background-size: cover;

  &__league {
    position: absolute;
    top: $vertical-main-padding;
    left: 0;

    & > button > img {
      width: 95px;
      height: 129px;
    }

    @media (max-width: $screen-max-width-mobile) {
      top: $vertical-main-padding-mobile;
      left: 0;
      & > button > img {
        width: 76px;
        height: 103px;
      }
    }
  }

  &__car {
    background-image: url('../../assets/images/backgrounds/backgroundSelectedCar.svg');
    background-size: contain;
    height: 100%;
    @media (max-width: $screen-max-width-mobile) {
      height: auto;
    }

    & > img {
      max-width: 100%;
      max-height: 100%;
      @media (max-width: $screen-max-width-mobile) {
        max-width: 314px;
        max-height: 210px;
        margin-bottom: 20px;
      }
    }
  }

  &__status {
    position: absolute;
    top: ($vertical-main-padding + 10px);
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;

    & > button {
      img {
        height: 30px;
        width: 30px;
      }
    }

    &__assets {
      display: flex;
      flex-direction: row;
      gap: 25px;
      margin-bottom: 16px;
      margin-right: 6px;
    }
  }

  .bottomContainer {
    $bottomContainerHeight: 30px;

    position: absolute;
    top: $statusBar-height - $bottomContainerHeight - 10px;
    left: 0;
    height: $bottomContainerHeight;

    display: flex;
    flex-direction: row;
    gap: 16px;

    .carBonus {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;

      font-size: 16px;
      color: white;
    }

    .deleteButton {
      $button-height: $bottomContainerHeight;
      $button-width: 65px;
      $img-size: 20px;


      display: flex;
      justify-content: center;
      align-items: center;

      width: $button-width;
      height: $button-height;
      background: #FE5A5A;
      border: 2px solid #CD0000;
      box-shadow: 0px -5px 3px 0px #EA1E1E inset, 0px 5px 3px 0px #FF8888 inset, 0px 2px 4px 1px #00000040;

      border-radius: 8px;



      & > img {
        max-width: 100%;
        max-height: $img-size;

        @media (max-width: $screen-max-width-mobile) {

        }
      }
    }
  }
}

@keyframes movingFlare {
  0% {
    left: -30px;
    margin-left: 0px;
  }
  10% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}

@keyframes scaling {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  5% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  10% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.jumpFlicker {
  /* Важные стили для анимации */
  position: relative;
  overflow: hidden;

  animation: scaling 10s ease-in-out 0.05s infinite;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(
                    to bottom,
                    rgba(255, 255, 255, 0.5) 0%,
                    rgba(255, 255, 255, 1) 50%,
                    rgba(255, 255, 255, 0.5) 100%
    );
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 10s ease-in-out 0.05s infinite;
  }
}

.toolbar {
  display: flex;
  min-height: calc(100vh - $statusBar-height);
  justify-content: center;
  background: #cf6bff;
  border: 2px solid #8c49ba;
  box-shadow: inset 0px 3px 1px #e6b3ff;
  @media (max-width: $screen-max-width-mobile) {
  }

  &_container {
    display: grid;
    grid-template-columns: (2 * $panel-max-width + $container-panel-gap) $panel-max-width;
    grid-template-rows: minmax(100%, min-content);
    height: min-content;
    max-height: 100%;
    justify-content: center;
    gap: $container-panel-gap;
    padding: $container-panel-padding;
    background: #c341ff;
    box-shadow: inset 0 0 4px 4px rgba(187, 40, 255, 0.62), inset 0 0 1px 2px rgba(187, 40, 255, 0.99);
    border-radius: 20px;
    @media (max-width: $screen-max-width-tablet) {
      grid-template-columns: $panel-max-width $panel-max-width;
    }
    @media (max-width: $screen-max-width-mobile) {
      grid-template-columns: ($panel-max-width + 2px);
      grid-template-rows: minmax(min-content, 100%) 1fr;
      gap: $container-panel-gap-mobile;
      padding: ($container-panel-gap-mobile + 2px);
    }

    &_unwrap {
      grid-template-columns: (3 * $panel-max-width + 2 * $container-panel-gap);
      @media (max-width: $screen-max-width-tablet) {
        grid-template-columns: (2 * $panel-max-width + $container-panel-gap);
      }
      @media (max-width: $screen-max-width-mobile) {
        grid-template-columns: ($panel-max-width + 8px);
      }
    }

    & > .fmContainer {
      display: flex;
      height: min-content;
      gap: $container-panel-gap;
      flex-wrap: wrap;
      @media (max-width: $screen-max-width-tablet) {
        max-width: $panel-max-width;
      }
      @media (max-width: $screen-max-width-mobile) {
        gap: $container-panel-gap-mobile;
      }
    }

    & > div:last-child {
      max-height: calc(100vh - $statusBar-height - 2 * $vertical-main-padding-mobile - 2 * $container-panel-padding - 30px);
      @media (max-width: $screen-max-width-tablet) {
        min-height: 233px;
      }
      @media (max-width: $screen-max-width-mobile) {
        min-height: initial;
        max-height: initial;
      }
    }
  }

  &__playButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: -50px;
    right: 0;
    width: 155px;
    height: 56px;

    &_container {
      position: relative;
      width: 100%;
      height: 47px;
      background: #cf6bff;
      border: 2px solid #8c49ba;
      box-shadow: inset 0 3px 1px #e6b3ff;
      border-radius: 23px 23px 0 0;
      border-bottom: none;

      & > button {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 135px;
        height: 105%;
        background: #4eff38;
        border: 1px solid #8c49ba;
        box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.25), inset 0px -3px 2px #18d900;
        border-radius: 18px;
        padding-top: 1px;

        & > svg > text {
          font-size: 28px;
          fill: #ffffff;
          stroke: #26d124;
          stroke-width: 5px;
          text-transform: uppercase;
        }
      }

      & > button:disabled {
        background: #6e6e6e;
        border: 1px solid #333131;
        box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.25), inset 0 -3px 2px #000000;

        & > svg > text {
          fill: #a8a8a8;
          stroke: #ffffff;
        }
      }
    }

    &_extBg {
      width: 151px;
      height: 6px;
      background: #cf6bff;
    }
  }
}

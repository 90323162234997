.text
  color: #FFF
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35)
  font-size: 22px
  font-style: normal
  font-weight: 400
  line-height: 19px
  letter-spacing: 0.66px
  text-align: center

.killerName
  color: red

@media (max-width: 1100px)
  .text
    font-size: 20px

//@media (max-width: 600px)
//  .text
//    font-size: 18px

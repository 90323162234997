.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
  &_column {
    flex-direction: column;
  }
  &_noGap {
    gap: 0;
  }
  &_inactive {
    opacity: 50%;
  }
}

.icon {
  display: inline-block;
  background-image: url('../../assets/images/common/icon-coin.png');
  background-size: contain;
  background-repeat: no-repeat;
  &_xSmall {
    width: 10px;
    height: 10px;
  }
  &_small {
    width: 16px;
    height: 16px;
  }
  &_big {
    width: 30px;
    height: 30px;
  }
}
.value {
  display: inline-block;
  &_small {
    font-size: 10px;
  }
  &_big {
    font-size: 14px;
  }
  &_margin {
    margin-left: 3px;
  }
  &_factory {
    fill: #ffffff;
    stroke: #d14ed8;
    stroke-width: 3px;
  }
  &_parking {
    fill: #ffffff;
    stroke: #00a3d2;
    stroke-width: 3px;
  }
}

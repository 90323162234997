.container {
  &_top {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 667px;
  background-image: url('../../assets/images/congrats/bg-flash.png');
  background-size: contain;
  background-position: 50% calc(50% - 10px);
  background-repeat: no-repeat;
  @media (max-width: 1024px) {
    background-size: cover;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5px;
  &__title {
    font-size: 36px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #bd3cd2;
    stroke-width: 1.5px;
    text-transform: capitalize;
  }
  &__description {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    fill: #ffffff;
    stroke: #bd3cd2;
    stroke-width: 0.5px;
  }
}

.car {
  &_image {
    width: 272px;
    //height: 200px;
    margin-top: 55px;
    margin-bottom: 40px;
  }
}

.buttonsGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  gap: 8px;
}

.buttonGreat {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffb800;
  border: 1.5px solid #cc9300;
  box-shadow: inset 0px 4px 4px #ffe68b, inset 0px -3px 2px #cd7c02;
  border-radius: 16px;
  width: 80%;
  padding: 5px 13px;
  &__label {
    font-size: 16px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #e78a00;
    stroke-width: 1px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    text-transform: capitalize;
  }
}

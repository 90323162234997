.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 10px;
  padding: 0 20px;
  width: 100%;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .content {
    display: flex;
    flex-direction: column;

    width: 100%;

    .text {
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;

      text-shadow: 1px 2px black;
    }

    .slider {
      width: 100%;

      .input {
        background: linear-gradient(to right, #F0C62A 0%, #F0C62A 50%, #fff 50%, #fff 100%);
        width: 100%;
        border-radius: 8px;
        height: 7px;
        outline: none;
        -webkit-appearance: none;

        &[type='range']::-webkit-slider-thumb {
          $size: 12px;

          -webkit-appearance: none;

          width: $size;
          height: $size;
          border: 1px solid #000000;
          cursor: ew-resize;
          background: #434343;
          border-radius: 100%;
        }
      }

      /** FF*/
      input[type="range"]::-moz-range-progress {
        background-color: #F0C62A;
      }

      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color:#F0C62A;
      }
    }
  }
}

.score {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 28px;
  background: linear-gradient(180deg, #ff75ff 0%, #ffa895 100%);
  border: 2px solid #a32b97;
  border-radius: 10px;
  & > .item:last-child {
    margin-top: 5px;
  }
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 16px;
  &__title {
    font-size: 18px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #d644e1;
    stroke-width: 4px;
    text-transform: capitalize;
  }
  &__close {
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.logo {
  width: 60px;
  height: 60px;
  margin: 8px 0 14px 0;
  background-image: url('../../assets/images/score/logo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: drop-shadow(1px 2px 15px rgba(0, 0, 0, 0.25));
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #ff82e3;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 4px 4px 4px 15px;
  &__value {
    font-size: 14px;
    letter-spacing: 0.03em;
    fill: #ffffff;
    stroke: #d644e1;
    stroke-width: 3px;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 74px;
    border-radius: 10px;
    padding-left: 3px;
    & > span:first-child {
      display: flex;
      justify-content: center;
      width: 68%;
    }
    &_rewarded {
      background: #ff4381;
      border: 1px solid #db1152;
      box-shadow: inset 0px 2px 1px #fa78a3, inset 0px -2px 2px #de2b65;
      &__label {
        font-size: 11px;
        letter-spacing: 0.03em;
        fill: #ffffff;
        stroke: #db1152;
        stroke-width: 2px;
        text-transform: lowercase;
      }
    }
    &_hard {
      background: #40f400;
      border: 1px solid #3bb600;
      box-shadow: inset 0px 2px 1px rgba(166, 255, 154, 0.3), inset 0px -2px 2px #24ce0d;
      &__label {
        font-size: 13px;
        letter-spacing: 0.03em;
        fill: #ffffff;
        stroke: #13a001;
        stroke-width: 2px;
      }
    }
    &__icon {
      display: inline-block;
      width: 17px;
      height: 17px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &_rewarded {
        background-image: url('../../assets/images/score/icon-rewarded.png');
      }
      &_hard {
        background-image: url('../../assets/images/score/icon-hard.png');
      }
    }
  }
}

.container
  position: absolute
  height: 100vh
  width: 100vw
  z-index: 50
  overflow: hidden

.disk
  position: absolute
  width: 125px
  height: 125px
  border-radius:  50%
  background-image: url('../../../../public/textures/joystick/joystickBackground.svg')
  background-size: cover

.stick
  position: absolute
  width:  37px
  height:  37px
  border-radius:  50%
  background-image: url('../../../../public/textures/joystick/joystickCenter.svg')
  background-size: cover

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  gap: 10px;
  padding: 0 20px;
  width: 100%;


  .text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: white;
    text-shadow: 1px 2px black;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }

  .round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }


  input:checked + .slider {
    background-color: #F0C62A;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #F0C62A;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.main
  font-family: "Droid Sans Mono Dotted", serif

  width: 100%
  display: flex
  flex-direction: column
  align-items: start
  gap: 50px
  padding: 10px

.controller
  font-family: "Droid Sans Mono Dotted", serif
  display: flex
  flex-direction: row
  gap: 10px

  button
    padding: 3px 7px
    background: grey
    border-radius: 25px

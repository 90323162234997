.container
  display: flex
  flex-direction: column
  align-items: end

  gap: 8px 0

  color: white

.bar
  background: #000000
  padding-left: 10px
  border-radius: 11px

  width: 155px
  height: 24px

  display: flex
  align-items: center

  font-size: 16px

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) inset, 0 4px 4px 0 rgba(0, 0, 0, 0.2)


  .position
    width: 15px
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5)


  .area
    text-align: center
    flex-grow: 1
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5)


  .img
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden

    width: 50px
    height: 50px

    background: rgba(216, 216, 216, 1)
    border: 2px solid rgb(160, 65, 204)

    border-radius: 7px
    position: relative
    right: -1px
    top: -5px

    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2)

    .avatar
      width: inherit
      height: inherit


.name
  text-align: center
  font-size: 22px
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5)
  width: 100%

@media (max-width: 600px)
  .container
    gap: 3px 0

  .bar
    width: 80px
    height: 18px
    font-size: 12px

    .img
      width: 30px
      height: 30px

  .name
    font-size: 16px

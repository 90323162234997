@font-face {
  font-family: 'DR Agu';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/DRAgu/DRAgu.eot');
  src: url('./assets/fonts/DRAgu/DRAgu.woff2') format('woff2'), url('./assets/fonts/DRAgu/DRAgu.woff') format('woff'),
    url('./assets/fonts/DRAgu/DRAgu.ttf') format('truetype'), url('./assets/fonts/DRAgu/DRAgu.svg#svgFontName') format('svg');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  -webkit-tap-highlight-color: transparent;
}

body,
button {
  font-family: 'DR Agu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  /* -webkit-tap-highlight-color: transparent; */
  &:active {
    opacity: 70%;
  }
  &:disabled {
    cursor: auto;
    opacity: 50%;
  }
}

.app {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

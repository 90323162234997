.container
  position: relative
  width: 80px
  height: 30px

  display: flex
  align-items: center
  justify-content: right

  border-radius: 21px
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))
  border: 2px solid rgba(190, 190, 190, 1)

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1) inset, 0 4px 4px 0 rgba(0, 0, 0, 0.2)


.text
  font-size: 20px
  padding-right: 5px
  color: white

.img
  width: 50px
  height: 50px
  position: absolute
  left: -15px

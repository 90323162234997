.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  background: #f28ce2;
  border: 1px solid #c330e3;
  box-shadow: inset 0px 3px 2px #ffbff5, inset 0px -3px 2px #d972c9;
  border-radius: 20px;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  &__title {
    font-size: 20px;
    line-height: 12px;
    fill: #ffffff;
    stroke: #d14ed8;
    stroke-width: 4px;
  }
  &__close {
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content {
  margin-top: 5px;
  padding: 7px 5px 7px 7px;
  background: #f4ceee;
  border-radius: 12px;
  &_container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    border-radius: 12px;
    max-height: 560px;
    overflow-y: auto;
    padding-right: 7px;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ed87dd;
      // border: 0.5px solid #ff74e9;
      box-shadow: inset 2px 1px 1px rgba(255, 255, 255, 0.25), inset -1px -1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: #d289f4;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      background-clip: padding-box;
      margin: 3px;
    }
  }
}

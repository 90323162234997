@keyframes tube-1an {
  0% {
    x: -19;
    y: -28;
  }
  13% {
    x: -19;
    y: -9;
  }
  32% {
    x: 8;
    y: 12;
  }
  68% {
    x: 40;
    y: 12;
  }
  87% {
    x: 60;
    y: -9;
  }
  100% {
    x: 60;
    y: -28;
  }
}

@keyframes tube-2an {
  0% {
    x: -19;
    y: -28;
  }
  13% {
    x: -19;
    y: -9;
  }
  32% {
    x: 8;
    y: 12;
  }
  68% {
    x: 102;
    y: 12;
  }
  87% {
    x: 122;
    y: -9;
  }
  100% {
    x: 122;
    y: -28;
  }
}

@keyframes tube-3an {
  0% {
    x: -19;
    y: -28;
  }
  13% {
    x: -19;
    y: -9;
  }
  27% {
    x: 8;
    y: 12;
  }
  73% {
    x: 167;
    y: 12;
  }
  87% {
    x: 187;
    y: -9;
  }
  100% {
    x: 187;
    y: -28;
  }
}

@keyframes tube-4an {
  0% {
    x: -19;
    y: -28;
  }
  13% {
    x: -19;
    y: -9;
  }
  25% {
    x: 8;
    y: 12;
  }
  75% {
    x: 228;
    y: 12;
  }
  87% {
    x: 250;
    y: -9;
  }
  100% {
    x: 250;
    y: -28;
  }
}

.tube-1-an {
  animation: tube-1an 2s infinite linear;
}

.tube-2-an,
.tube-2-an-1 {
  animation: tube-2an 3s infinite linear;
}
.tube-2-an-1 {
  animation-delay: 1s;
}

.tube-3-an,
.tube-3-an-1,
.tube-3-an-2,
.tube-3-an-3 {
  animation: tube-3an 4s infinite linear;
}

.tube-3-an-1 {
  animation-delay: 1s;
}

.tube-3-an-1 {
  animation-delay: 2s;
}

.tube-3-an-3 {
  animation-delay: 3s;
}

.tube-4-an,
.tube-4-an-1,
.tube-4-an-2,
.tube-4-an-3,
.tube-4-an-4 {
  animation: tube-4an 5s infinite linear;
}

.tube-4-an-1 {
  animation-delay: 1s;
}
.tube-4-an-2 {
  animation-delay: 2s;
}

.tube-4-an-3 {
  animation-delay: 3s;
}

.tube-4-an-4 {
  animation-delay: 4s;
}


.stories {
  position: fixed;
  left: 0;
  bottom: -1665px;
  height: 1648px !important;
  width: 926px !important;
  & .img {
    height: 100%;
    width: 100%;
    z-index: 5;
  }

  & .car {
    position: absolute;
    top: 714px;
    left: 184px;
    width: 554px;
    height: 392px;
  }

  & .text {
    text-align: center;
    position: absolute;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 34px!important;
    top: 1251px;
    width: 100%;
    -webkit-text-stroke: 1.5px #0011AC;
  }
}

.container {
  width: 100%;
  background-image: url('../../assets/images/backgrounds/backgroundLoader.svg');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 50%;
  margin-bottom: 50px;
  & img {
    width: 100%;
  }
}

.text {
  font-family: DR Agu;
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03em;
  text-align: left;
	color: #B7BBFF;

}

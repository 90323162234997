.leftContainer
  position: absolute

  top: 35px
  right: 60px

  gap: 20px
  display: flex
  align-items: end
  flex-direction: column

.counters
  display: flex
  align-items: center
  justify-content: right
  gap: 25px

  position: absolute
  top: 35px
  left: 50vw
  transform: translate(-50%, 0)

@media (max-width: 1100px)
  .leftContainer
    right: 40px
    gap: 10px
    top: 25px

  .counters
    top: 25px

@media (max-width: 600px)
  .leftContainer
    right: 20px
    gap: 10px
    top: 15px
    width: 90px

  .counters
    left: 5%
    transform: none
    top: 15px
    flex-direction: column
    gap: 10px

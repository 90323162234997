$types: (
  bronze: (
    bg: #ff99ab,
    shadow: (
      inset 0px 3px 2px #ffc6e1,
      inset 0px -3px 2px #df7fae,
    ),
    stroke: #d1699b,
  ),
  silver: (
    bg: #cba2ff,
    shadow: (
      inset 0px 3px 2px #dfc5ff,
      inset 0px -3px 2px #b67bff,
    ),
    stroke: #ac69ff,
  ),
  gold: (
    bg: #ffb18f,
    shadow: (
      inset 0px 3px 2px #ffd2be,
      inset 0px -3px 2px #e08e6a,
    ),
    stroke: #d97448,
  ),
  ruby: (
    bg: #fd8fff,
    shadow: (
      inset 0px 3px 2px #ffbff5,
      inset 0px -3px 2px #d972c9,
    ),
    stroke: #d14ed8,
  ),
  brilliant: (
    bg: #86c5fe,
    shadow: (
      inset 0px 3px 2px #c6e4ff,
      inset 0px -3px 2px #6699c8,
    ),
    stroke: #4f94d4,
  ),
);

.container {
  display: flex;
  flex-direction: column;
  max-width: 318px;
  border: 1px solid #e58ff8;
  border-radius: 20px;
  padding: 12px 10px;
  @each $type, $map in $types {
    &_#{$type} {
      background-color: map-get($map, bg);
      background-image: url('../../../assets/images/rewards/bg-stars-' + $type + '.png');
      background-size: 95%;
      background-position: bottom 12px center;
      background-repeat: no-repeat;
      box-shadow: map-get($map, shadow);
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  @each $type, $map in $types {
    &__cup_#{$type} {
      display: inline-block;
      width: 122px;
      height: 110px;
      background-image: url('../../../assets/images/leagues/cup-' + $type + '-on.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 122px);
    gap: 3px;
    margin-top: 8px;
    &__league {
      font-size: 19px;
      fill: #ffffff;
      stroke-width: 4px;
    }
    &__description {
      font-family: Arial, sans-serif;
      font-weight: 700;
      font-size: 11px;
      fill: #ffffff;
      stroke-width: 2px;
    }
    @each $type, $map in $types {
      &_#{$type} {
        stroke: map-get($map, stroke);
      }
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 9px;
  & > div:first-child {
    width: 100%;
  }
}
